@charset "UTF-8";

// ----------------------------------------------
.card-collapse {
  overflow: hidden;
  background-color: #fcf2e7;
  border: 0;
  border-radius: 10px;

  &.blue {
    background-color: #e0eeff;

    a {
      background-color: #024496;
    }
  }

  + .card-collapse {
    margin-top: 22px;

    @include media-breakpoint-up(md) {
      margin-top: 33px;
    }
  }

  .card-body {
    padding: 18px 24px 24px;
    font-size: 14px;
    font-weight: bold;
    line-height: 2.28;

    @include media-breakpoint-up(md) {
      padding: 26px 40px 38px;
      font-size: 16px;
      line-height: 2.125;
    }
  }

  a {
    position: relative;
    display: block;
    padding: 16px 45px 15px 75px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.875;
    text-decoration: none;
    color: #fff;
    background-color: #624a2d;
    border-radius: 10px 10px 0 0;

    @include media-breakpoint-up(md) {
      padding: 26px 60px 25px 102px;
      font-size: 18px;
      line-height: 1.5;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(0);
      }
    }

    &:hover {
      opacity: .8;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      right: 22px;
      bottom: 0;
      display: block;
      width: 17px;
      height: 3px;
      margin-top: auto;
      margin-bottom: auto;
      background-color: #fff;
      content: "";
      transition: all .3s ease;

      @include media-breakpoint-up(md) {
        right: 30px;
        height: 4px;
      }
    }

    &:after {
      transform: rotate(90deg);
    }

    .text-header {
      position: absolute;
      left: 21px;
      display: inline-block;
      margin-top: 2px;
      font-size: 22px;
      line-height: 1.1;

      @include media-breakpoint-up(md) {
        left: 26px;
        margin-top: -2px;
        font-size: 26px;
      }
    }
  }
}

// ----------------------------------------------
a {
  &.card {
    @include transition(all $hover-transition-speed ease);
  }
}

// ----------------------------------------------
.card {
  &.border-radius-base {
    border-radius: $border-radius;
  }

  &.card-title-on-border {
    position: relative;
    padding-top: 35px;

    .title {
      position: absolute;
      top: -.75em;
      right: 0;
      left: 0;
      padding-right: 1.5%;
      padding-left: 1.5%;
      margin: 0;

      .bg {
        display: inline-block;
        padding: 3px 12px;
        line-height: 1.1;
        background: #fff;
        border: 1px solid #000;
      }
    }
  }
}

// ----------------------------------------------
.card-0 {
  background: none;
  border: 0;
  border-radius: 0;

  .card-body {
    padding: 0;
  }
}

// ----------------------------------------------
.card-dot {
  position: relative;
  max-width: 895px;
  margin-top: 61px;
  margin-right: auto;
  margin-left: auto;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .12em;
  line-height: 2.143;
  border: 0;
  border-radius: 18px;

  @include media-breakpoint-down(md) {
    margin-top: 18px;
    font-size: 13px;
    line-height: 2.154;
  }

  &:before,
  &:after {
    position: absolute;
    top: 17px;
    width: 14px;
    height: 14px;
    background-color: #d7715f;
    border-radius: 50%;
    content: "";

    @include media-breakpoint-down(md) {
      width: 11px;
      height: 11px;
    }
  }

  &:before {
    left: 19px;

    @include media-breakpoint-down(md) {
      left: 17px;
    }
  }

  &:after {
    right: 19px;

    @include media-breakpoint-down(md) {
      right: 17px;
    }
  }

  &.blue {
    &,
    .card-body {
      &:before,
      &:after {
        background-color: #024496;
      }
    }
  }

  .card-body {
    position: relative;
    padding: 38px 40px 34px;

    @include media-breakpoint-down(md) {
      padding: 36px 18px 29px;
    }

    &:before,
    &:after {
      position: absolute;
      bottom: 17px;
      width: 14px;
      height: 14px;
      background-color: #d7715f;
      border-radius: 50%;
      content: "";

      @include media-breakpoint-down(md) {
        width: 11px;
        height: 11px;
      }
    }

    &:before {
      left: 19px;

      @include media-breakpoint-down(md) {
        left: 17px;
      }
    }

    &:after {
      right: 19px;

      @include media-breakpoint-down(md) {
        right: 17px;
      }
    }
  }

  .card-title {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: .03em;
    color: #d7715f;

    @include media-breakpoint-down(md) {
      margin-bottom: 21px;
      font-size: 22px;
    }
  }
}

// ----------------------------------------
.card-map {
  margin-top: 4px;
  margin-bottom: 38px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: .12em;
  line-height: 2;
  border-radius: 10px;
  border: 0;

  @include media-breakpoint-down(md) {
    margin-top: 40px;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 1.8;
  }

  .card-body {
    padding: 9px 15px;

    @include media-breakpoint-down(md) {
      padding: 13px;
    }
  }

  &.card-map-orange {
    background-color: #fdf6ef;
  }

  &.card-map-yellow {
    background-color: #fdfbe7;
  }
}

// ----------------------------------------
.card-white {
  position: relative;
  max-width: 371px;
  margin-right: auto;
  margin-bottom: 73px;
  margin-left: auto;
  border: 0;
  border-radius: 19px;

  @include media-breakpoint-down(md) {
    max-width: 277px;
    margin-bottom: 54px;
    border-radius: 19px;
  }

  .card-img-top {
    width: auto;
    border-top-right-radius: 19px;
    border-top-left-radius: 19px;

    @include media-breakpoint-down(md) {
      border-top-right-radius: 19px;
      border-top-left-radius: 19px;
    }
  }

  .card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 59px;
    padding: 10px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: .04em;
    line-height: 1.556;
    color: #d7715f;
    background-color: #fff;
    border-radius: 37px;
    text-align: center;

    @include media-breakpoint-down(md) {
      min-height: 53px;
      font-size: 17px;
      line-height: 1.412;
    }
  }

  .card-title-container {
    max-width: 287px;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%);

    @include media-breakpoint-down(md) {
      max-width: 249px;
    }
  }

  .card-body {
    padding: 29px 15px 26px;

    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-bottom: 17px;
    }
  }
}
