@charset "UTF-8";

// ----------------------------------------------
footer {
  padding-top: 28px;
  padding-bottom: 28px;
  font-size: 10px;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    padding-top: 36px;
    padding-bottom: 36px;
    font-size: 12px;
  }
}

// ----------------------------------------------
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-area {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 8888px;
  }

  .img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .img-scale,
  .img-fade {
    transition: .8s all ease;
  }

  &.hidden-loading {
    background-color: transparent;
    transition: .3s all ease;

    .img-scale {
      opacity: 0;
    }
  }

  .img-house {
    @include media-breakpoint-down(md) {
      width: 278px;
    }
  }

  &.loading-disabled {
    z-index: -1;
  }

  .logo-loading {
    opacity: 1;

    @include media-breakpoint-down(md) {
      width: 128px;
    }
  }
}

// ----------------------------------------------
@keyframes dot-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot-blink-up-down {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }

  50% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

@keyframes balloon-up-down {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes movingHorizontal {
  0% {
    transform: translateX(50px);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50px);
  }
}

@keyframes falling {
  0% {
    transform: translate(0, -50px);
    opacity: 0;
  }

  100% {
    transform: translate(-4px, 0);
  }
}

@keyframes leaves {
  0% {
    transform: translate(0, -50px);
    opacity: 0;
  }

  100% {
    transform: translate(2px, 0);
  }
}

// ----------------------------------------------
.dot-blink {
  animation: dot-blink 1s infinite;
}

// ----------------------------------------------
.dot-blink-up-down {
  animation: dot-blink-up-down 2s infinite;

  @include media-breakpoint-down(md) {
    animation-duration: 2.5s;
  }
}

// ----------------------------------------------
.balloon-up-down {
  animation: balloon-up-down 2s infinite;

  @include media-breakpoint-down(md) {
    animation-duration: 2.5s;
  }
}

// ----------------------------------------------
.blink-delay {
  animation-delay: .2s;
}

// ----------------------------------------------
.animation-right-left {
  animation: movingHorizontal 16s infinite;
}

// ----------------------------------------------
.animation-falling {
  animation: falling 3s infinite;
}

// ----------------------------------------------
.animation-falling-1 {
  animation: leaves 3s infinite;
}

// ----------------------------------------------
.animation-delay-100 {
  animation-delay: 100ms;
}

// ----------------------------------------------
.animation-delay-300 {
  animation-delay: 300ms;
}
