@charset "UTF-8";

// ----------------------------------------------
.modal-privacy-policy {
  font-size: 14px;

  .modal-dialog {
    width: 100%;
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down(xl) {
      max-width: 900px;
    }

    @include media-breakpoint-down(lg) {
      max-width: 750px;
    }

    @include media-breakpoint-down(md) {
      max-width: 350px;
    }
  }

  .modal-body {
    padding: 30px 80px 60px;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }
  }

  .modal-title {
    padding: 0;
    font-size: 22px;
    font-weight: bold;
  }

  .modal-header {
    padding: 30px 50px 0 80px;
    border-bottom: 0;

    @include media-breakpoint-down(md) {
      padding: 15px 15px 0;
    }
  }

  .modal-content {
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 50%);
  }

  dl {
    margin-bottom: 20px;
  }
}
