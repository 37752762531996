@charset "UTF-8";

// ----------------------------------------------
.swiper-container {
  .swiper-slide {
    font-size: 13px;
    font-weight: 500;
    line-height: 2.153;
  }

  .slide-title {
    margin-top: 21px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      margin-top: 36px;
      margin-bottom: 18px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 45px;
    height: 45px;
    background-color: #d7715f;
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      width: 68px;
      height: 68px;
    }

    &:after {
      width: 8px;
      height: 16px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.564' height='20.128' viewBox='0 0 11.564 20.128'%3E%3Cpath d='M1642.443 2063.761l-7.943 7.943 7.943 7.943' transform='translate(-1633 -2061.64)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E") no-repeat 50% 50% / contain;
      content: "";

      @include media-breakpoint-up(md) {
        width: 12px;
        height: 20px;
      }
    }

    &:hover {
      opacity: .8;
    }

    &.swiper-button-white-sp {
      @include media-breakpoint-down(md) {
        background-color: #fff;
      }

      &:after {
        @include media-breakpoint-down(md) {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.564' height='20.128' viewBox='0 0 11.564 20.128'%3E%3Cpath d='M1642.443 2063.761l-7.943 7.943 7.943 7.943' transform='translate(-1633 -2061.64)' fill='none' stroke='%23d7715f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E");
        }
      }

      &.blue {
        &:after {
          @include media-breakpoint-down(md) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.564' height='20.128' viewBox='0 0 11.564 20.128'%3E%3Cpath d='M1642.443 2063.761l-7.943 7.943 7.943 7.943' transform='translate(-1633 -2061.64)' fill='none' stroke='%23024496' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E");
          }
        }
      }
    }

    &.blue {
      background-color: #024496;
    }
  }

  .swiper-button-next {
    &:after {
      transform: scale(-1);
    }
  }
}

// ----------------------------------------------
.swiper-simple {
  .swiper-slide {
    width: 317px;

    @include media-breakpoint-up(md) {
      width: 345px;
    }
  }

  .slide-img {
    border-radius: 32px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 110px;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      top: 120px;
    }
  }

  .swiper-button-prev {
    left: 0;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(md) {
      left: -12px;
      transform: translate(0, -50%);
    }

    @include media-breakpoint-up(xl) {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  .swiper-button-next {
    right: 0;
    transform: translate(50%, -50%);

    @include media-breakpoint-up(md) {
      right: -12px;
      transform: translate(0, -50%);
    }

    @include media-breakpoint-up(xl) {
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}

// ----------------------------------------------
.swiper-voice {
  @include media-breakpoint-down(md) {
    padding-left: 5px;
  }

  .swiper-slide {
    width: 186px;

    @include media-breakpoint-up(md) {
      width: 244px;
    }
  }

  .slide-title {
    @include media-breakpoint-down(md) {
      margin-top: 19px;
      margin-bottom: 14px;
    }
  }

  .slide-body {
    line-height: 2;

    @include media-breakpoint-up(md) {
      padding-right: 10px;
      padding-left: 10px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  .slide-img {
    width: 186px;
    height: 186px;
    object-fit: contain;
    border-radius: 50%;

    @include media-breakpoint-up(md) {
      width: 244px;
      height: 244px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 174px;
    right: -17px;

    @include media-breakpoint-up(md) {
      top: 0;
      right: 0;
      left: auto;
      margin-top: -130px;
    }
  }

  .swiper-button-prev {
    right: auto;
    left: -17px;

    @include media-breakpoint-up(md) {
      right: 0;
      left: auto;
      margin-right: 88px;
    }
  }
}
