@charset "UTF-8";

// ----------------------------------------------
.container {
  max-width: 1150px;

  @include media-breakpoint-down(md) {
    padding-right: 26px;
    padding-left: 26px;
  }
}

// ----------------------------------------------
@mixin row-variant($parent, $grid-gutter-width) {
  #{$parent} {
    margin-right: -$grid-gutter-width;
    margin-left: -$grid-gutter-width;

    > div,
    > li {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }
  }
}

// ----------------------------------------------
@include row-variant(".row-0", 0);
@include row-variant(".row-5", 5px);
@include row-variant(".row-10", 10px);

// ----------------------------------------------
@include media-breakpoint-down(md) {
  @include row-variant(".row-5-md", 5px);
}

// ----------------------------------------------
@include media-breakpoint-up(xl) {
  @include row-variant(".row-15-xl", 15px);
  @include row-variant(".row-25-xl", 25px);
  @include row-variant(".row-32-xl", 32px);
  @include row-variant(".row-37-xl", 37px);
}

// ----------------------------------------------
.col-base {
  margin-bottom: 28px;

  @include media-breakpoint-down(md) {
    margin-bottom: 17px;
  }

  @include media-breakpoint-up(md) {
    float: left;
  }

  &.col-base-1 {
    @include media-breakpoint-up(md) {
      width: 45%;
    }
  }

  &.col-base-2 {
    @include media-breakpoint-up(md) {
      width: 55%;
    }
  }
}

// ----------------------------------------------
.container-xs {
  @include media-breakpoint-down(md) {
    padding-right: 29px;
    padding-left: 29px;
  }
}
