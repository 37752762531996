@charset "UTF-8";

// ----------------------------------------------
.navbar {
  position: absolute;
  z-index: 10;
  align-items: flex-start;
  background-color: transparent;
  transition: background-color .3s ease;

  &.active {
    position: fixed;
  }

  .navbar-brand {
    position: relative;
    z-index: 1031;
    padding: 14px 10px;
    margin: 0;

    @include media-breakpoint-up(md) {
      padding-top: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 40px;
      padding-left: 50px;
    }

    img {
      @include media-breakpoint-down(md) {
        width: 94px;
        height: 54px;
      }
    }
  }

  .navbar-nav {
    height: 100vh;
    padding: 140px 20px 50px;
    overflow-y: scroll;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      justify-content: center;
      padding-top: 170px;
      overflow-y: auto;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 240px;
    }

    .nav-item {
      @include media-breakpoint-up(md) {
        margin-right: 15px;
        margin-left: 15px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 28px;
        margin-left: 28px;
      }

      + .nav-item {
        @include media-breakpoint-down(md) {
          margin-top: 14px;
        }
      }
    }

    .nav-link {
      @include text-shadow-outline-blurred(#fae8d5);
      font-size: 21px;
      font-weight: bold;
      color: #624a2d;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 31px;
        letter-spacing: .13em;
        text-align: left;
        writing-mode: vertical-lr;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 0;
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        letter-spacing: .15em;
      }

      &:hover {
        color: #d7715f;
      }

      &.active {
        color: #d7715f;
      }
    }
  }

  .navbar-collapse {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    max-height: 100vh;
    background-image: url("../img/common/bg-mountain-navbar-1.svg"), url("../img/common/cloud-1.svg");
    background-color: #fae8d5;
    background-repeat: no-repeat;
    background-position: -57px 98%, -504px -72px;
    background-size: auto, 730px;

    @include media-breakpoint-up(md) {
      background-image: url("../img/common/bg-mountain-navbar.svg"), url("../img/common/cloud-1.svg");
      background-position: calc(50% + 150px) 95%, -800px -130px;
      background-size: 150%, auto;
    }

    @include media-breakpoint-up(lg) {
      background-position: calc(50% + 200px) 95%, -744px -98px;
      background-size: auto;
    }

    @include media-breakpoint-down(md) {
      background-position: -57px 98%, -530px -72px;
    }

    @media screen and (max-height: 811px) {
      background-size: 105%, auto;
      background-position: 50% 98%, -530px -72px;
    }
  }
}

// ----------------------------------------------
.js-header {
  &.is-scrolled {
    position: fixed;
    top: -200px;
  }

  &.is-transition {
    @include transition(top .3s ease-in-out);
    will-change: top;
  }

  &.is-show {
    top: 0;
  }
}

// ----------------------------------------------
.menu-trigger {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1031;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: all .3s;

  @include media-breakpoint-up(md) {
    top: 30px;
    right: 30px;
    width: 78px;
    height: 78px;
  }

  @include media-breakpoint-up(lg) {
    top: 38px;
    right: 38px;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    background-color: #624a2d;
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    @include media-breakpoint-up(md) {
      opacity: .8;
    }
  }

  .icon-bar {
    position: absolute;
    left: 28%;
    display: inline-block;
    width: 21px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    transition: all .3s;

    @include media-breakpoint-up(md) {
      width: 42%;
      height: 3px;
    }

    &:nth-of-type(1) {
      top: calc(50% - 8px);

      @include media-breakpoint-up(md) {
        top: calc(50% - 13px);
      }
    }

    &:nth-of-type(2) {
      top: calc(50% - 1px);

      @include media-breakpoint-up(md) {
        top: calc(50% - 2px);
      }
    }

    &:nth-of-type(3) {
      top: calc(50% + 6px);

      @include media-breakpoint-up(md) {
        top: calc(50% + 9px);
      }
    }
  }

  &[aria-expanded="true"] {
    &,
    &:focus {
      box-shadow: none;
    }

    .icon-bar {
      &:nth-of-type(1) {
        transform: translateY(7px) rotate(-45deg);

        @include media-breakpoint-up(md) {
          transform: translateY(11px) rotate(-45deg);
        }
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-7px) rotate(45deg);

        @include media-breakpoint-up(md) {
          transform: translateY(-11px) rotate(45deg);
        }
      }
    }
  }

  &[aria-expanded="false"] {
    .icon-bar {
      &:nth-of-type(3) {
        width: 11px;

        @include media-breakpoint-up(md) {
          width: 23%;
        }
      }
    }
  }
}

// ----------------------------------------------
.collapse-parent {
  $space: 6px;

  position: relative;

  .collapse-child {
    @include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
    background-color: #fff;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: $zindex-dropdown;
    }

    @include media-breakpoint-down(md) {
      @include box-shadow(0 3px 6px rgba(0, 0, 0, .175));
    }

    ul {
      padding-top: $space;
      padding-bottom: $space;
    }
  }

  a {
    display: block;
    padding: $space 15px;
  }

  .collapsing {
    transition: height .2s ease;
  }
}
