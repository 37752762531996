@charset "UTF-8";

// ----------------------------------------------
.disable-scroll {
  position: absolute;
  left: 0;
  right: 0;
  overflow-y: hidden;
}

// ----------------------------------------------
.bg-light-orange {
  background-color: #fae8d5 !important;
}

.bg-yellow {
  background-color: #f9efa9 !important;
}

.bg-light-yellow {
  background-color: #fcf7d0 !important;
}

.bg-light-blue {
  background-color: #e0eeff !important;
}

// ----------------------------------------------
.bg-wave-white {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    width: 100%;
    height: 17px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    background-image: url("../img/common/wave.svg");
    background-position: 50% 0;
    background-size: 122px 17px;
    background-repeat: repeat-x;

    @include media-breakpoint-down(md) {
      height: 10px;
      background-size: 74px 10px;
      background-image: url("../img/common/wave-sp.png");
    }
  }

  &:before {
    @include media-breakpoint-up(md) {
      top: 0;
      transform: translateY(calc(1px - 100%));
      content: "";
    }
  }

  &:after {
    bottom: 0;
    content: "";

    @include media-breakpoint-up(md) {
      transform: rotate(180deg) translateY(calc(1px - 100%));
    }

    @include media-breakpoint-down(md) {
      bottom: -10px;
    }
  }
}

// ----------------------------------------------
.lh-md-2 {
  @include media-breakpoint-up(md) {
    line-height: 2 !important;
  }
}

// ----------------------------------------------
.max-width-446 {
  max-width: 446px !important;
}
