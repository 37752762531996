@charset "UTF-8";

// ----------------------------------------------
.title-border-bottom-wave {
  position: relative;
  padding-bottom: 32px;
  margin-bottom: 61px;
  font-weight: bold;
  font-size: 33px;
  letter-spacing: .12em;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding-bottom: 16px;
    margin-bottom: 32px;
    font-size: 24px;
  }

  &:before {
    position: absolute;
    width: 221px;
    height: 6px;
    bottom: 0;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    background-image: url("../img/icon/wave.svg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include media-breakpoint-down(md) {
      width: 155px;
      background-image: url("../img/icon/wave-sp.svg");
    }
  }

  &.light-green {
    &:before {
      background-image: url("../img/icon/wave-light-green.svg");

      @include media-breakpoint-down(md) {
        background-image: url("../img/icon/wave-green-sp.svg");
      }
    }
  }
}

// ----------------------------------------------
.title-brown {
  font-weight: bold;
  font-size: 36px;
  letter-spacing: .12em;
  line-height: 2;

  @include media-breakpoint-down(md) {
    font-size: 26px;
    line-height: 1.8;
  }

  &.title-md {
    font-size: 33px;
    line-height: 1.2;

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 1.23;
    }
  }
}

// ----------------------------------------------
.title-both-side-line {
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: .12em;
  line-height: 1.5;
  padding-right: 300px;
  padding-left: 300px;
  margin-top: 28px;
  margin-bottom: 50px;

  @include media-breakpoint-down(xl, (xl:1366px)) {
    padding-right: 22vw;
    padding-left: 22vw;
  }

  @include media-breakpoint-down (md) {
    padding-right: 90px;
    padding-left: 90px;
    margin-top: 20px;
    margin-bottom: 32px;
    font-size: 17px;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 265px;
    height: 6px;
    margin-top: auto;
    margin-bottom: auto;
    background-image: url("../img/icon/wave-orange.svg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include media-breakpoint-down(xl, (xl:1366px)) {
      width: 19.4vw;
    }

    @include media-breakpoint-down (md) {
      width: 76px;
      height: 5px;
      background-image: url("../img/icon/wave-orange-sp.svg");
    }
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &.light-green {
    @include media-breakpoint-up(md) {
      padding-right: 240px;
      padding-left: 240px;
      margin-top: 10px !important;
    }

    @include media-breakpoint-down (md) {
      padding-right: 100px;
      padding-left: 100px;
    }

    &:before,
    &:after {
      background-image: url("../img/icon/wave-light-green.svg");

      @include media-breakpoint-up(md) {
        width: 218px;
      }

      @include media-breakpoint-down (md) {
        width: 88px;
        background-image: url("../img/icon/wave-light-green-sp.svg");
      }
    }
  }
}

// -----------------------------------------
.title-brown-sm {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 33px;
  letter-spacing: .06em;
  line-height: 1.818;

  @include media-breakpoint-down (md) {
    font-size: 26px;
    line-height: 1.231;
  }
}
