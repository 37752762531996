@charset "UTF-8";

// ----------------------------------------------
label {
  cursor: pointer;

  &.error {
    padding: 5px 10px;
    font-size: 13px;
    color: #fff;
    cursor: default;
    background-color: #f00;
  }
}

// ----------------------------------------------
select {
  cursor: pointer;
}
