@charset "UTF-8";

// ----------------------------------------------
.badge {
  text-decoration: none;

  &.has-space {
    margin-bottom: 3px;
  }
}
