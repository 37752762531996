@charset "UTF-8";

// ----------------------------------------------
.section-column {
  padding-top: 50px;
  padding-bottom: 76px;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
    padding-bottom: 140px;
    background: url("../img/common/cloud.svg") no-repeat calc(50% - 580px) 22%, url("../img/common/bg-footprint.svg") no-repeat 0 70%;
    background-size: contain;
  }

  @include media-breakpoint-up(lg) {
    background-position: calc(50% - 740px) 40%, calc(50% + 213px) 94%;
    background-size: auto;
  }

  .container {
    max-width: 1190px;
  }

  .card {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 50px;
    }

    &.flex-md-row-reverse {
      .card-img-container {
        .caption {
          right: auto;
          left: 30px;

          @include media-breakpoint-up(md) {
            top: -30px;
            left: 50px;
          }

          @include media-breakpoint-up(lg) {
            left: 62px;
          }
        }
      }

      .card-body {
        @include media-breakpoint-up(md) {
          padding-right: 30px;
          padding-left: 30px;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 68px;
          padding-right: 80px;
          padding-left: 87px;
        }
      }
    }

    + .card {
      margin-top: 88px;

      @include media-breakpoint-up(md) {
        margin-top: 50px;
      }
    }

    .card-img-container {
      position: relative;

      @include media-breakpoint-up(md) {
        max-width: 49.2%;
      }

      .img {
        border-radius: 33px;
      }

      .caption {
        position: absolute;
        top: -36px;
        right: 32px;
        z-index: 1;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: .42em;
        writing-mode: vertical-rl;

        @include media-breakpoint-up(md) {
          top: -36px;
          right: 50px;
          font-size: 26px;
          letter-spacing: .45em;
        }

        @include media-breakpoint-up(lg) {
          right: 64px;
          font-size: 33px;
        }

        span {
          position: relative;
          display: inline-block;

          &:after {
            position: absolute;
            top: 40%;
            left: 54%;
            z-index: -1;
            width: 54px;
            height: 54px;
            background: url("../img/icon/circle-white.svg") no-repeat 50% 50% / contain;
            content: "";
            transform: translate(-50%, -50%);

            @include media-breakpoint-up(md) {
              width: 58px;
              height: 58px;
            }

            @include media-breakpoint-up(lg) {
              width: 74px;
              height: 74px;
            }
          }
        }
      }

      .icon {
        position: absolute;
        top: -47px;
        left: 11px;

        @include media-breakpoint-up(lg) {
          left: 90px;
        }
      }

      .icon-bird {
        @include media-breakpoint-down(md) {
          width: 61px;
          height: 53px;
        }
      }

      .icon-tree {
        top: -88px;
        right: 13px;
        left: auto;
        width: 89px;
        height: 96px;

        @include media-breakpoint-up(md) {
          top: -116px;
          right: 17px;
          width: 120px;
          height: 129px;
        }

        @include media-breakpoint-up(lg) {
          top: -136px;
          width: 146px;
          height: 157px;
        }
      }
    }

    .card-body {
      padding-top: 32px;
      font-size: 14px;
      line-height: 2.14;

      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-left: 30px;
        font-size: 16px;
        line-height: 2;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 54px;
        padding-left: 92px;
      }

      @include media-breakpoint-down(md) {
        letter-spacing: .11em;
      }

      .card-title {
        margin-bottom: 16px;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.538;

        @include media-breakpoint-up(md) {
          font-size: 30px;
          line-height: 1.667;
        }
      }

      .btn-container {
        margin-top: 33px;

        @include media-breakpoint-up(md) {
          max-width: 217px;
          margin-top: 35px;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-description {
  padding-top: 175px;
  padding-bottom: 128px;
  background-image: url("../img/section/description/tree-left.svg"), url("../img/section/description/tree-right.svg");
  background-position: 0 60%, 100% 65%;
  background-repeat: no-repeat, no-repeat;
  text-align: center;

  @include media-breakpoint-down(xl, (xl:1366px)) {
    background-size: 24.65vw 40vw, 35.3vw 40vw;
  }

  @include media-breakpoint-down(lg) {
    background-position-y: 100%;
  }

  @include media-breakpoint-down(md) {
    padding-top: 300px;
    padding-bottom: 26px;
    background: url("../img/section/description/bg-tree.svg") no-repeat 46% 0;
  }

  .text-vertical {
    font-weight: bold;
    font-size: 25px;
    letter-spacing: .16em;
    line-height: 2.36;
    writing-mode: vertical-rl;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      line-height: 2;
    }

    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 2.48;
    }
  }

  .text-vertical-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

// ----------------------------------------------
.section-flow-of-use {
  position: relative;
  padding-top: 95px;
  padding-bottom: 95px;

  @include media-breakpoint-between(lg, xl) {
    padding-bottom: 130px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 26px;
    padding-bottom: 42px;
  }

  &:after {
    @include media-breakpoint-up(md) {
      position: absolute;
      width: 287px;
      height: 271px;
      bottom: 0;
      right: 0;
      background: url("../img/section/flow-of-use/birds-with-mountains.svg") 100% 100%/cover no-repeat;
      content: "";
    }

    @include media-breakpoint-down(xl, (xl: 1366px)) {
      width: 21vw;
      height: 19.8vw;
    }

    // @include media-breakpoint-down(md) {
    //   width: 116px;
    //   height: 110px;
    // }
  }

  &.mountains-green {
    &:after {
      background-image: url("../img/section/flow-of-use/birds-with-mountains-green.svg");
    }
  }

  .body {
    padding: 70px 105px 79px;
    background-color: #f5d3b2;
    border-radius: 60px;

    @include media-breakpoint-down(xl) {
      padding-right: 60px;
      padding-left: 60px;
    }

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @include media-breakpoint-down(md) {
      padding: 38px 20px 20px;
      border-radius: 30px;
    }
  }
}

// ------------------------------------------
.section-case {
  position: relative;
  padding-top: 58px;
  padding-bottom: 12px;

  @include media-breakpoint-up(md) {
    padding-top: 167px;
  }

  &:before {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 134px;
      left: calc(50% - 704px);
      width: 316px;
      height: 264px;
      background: url("../img/icon/footprint.svg") no-repeat 0 0 / contain;
      content: "";
      filter: brightness(0) invert(1);
      transform: rotate(112deg);
    }

    @include media-breakpoint-down(xl) {
      left: -7%;
      width: 22.7vw;
      height: 21.2vw;
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 29px;
      padding-left: 29px;
    }
  }

  .title-section {
    margin-bottom: 26px;
  }

  .lead {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 72px;
    }
  }
}

// ------------------------------------------
.section-voice {
  padding-top: 10px;
  padding-bottom: 64px;

  @include media-breakpoint-up(md) {
    padding-bottom: 120px;
  }

  @include media-breakpoint-down(md) {
    position: relative;

    &:after {
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      background: url("../img/common/wave-sp.png") repeat-x 50% 0 / 74px 10px;
      content: "";
      transform: rotate(180deg);
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 29px;
      padding-left: 29px;
    }
  }

  .title-section {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 26px;
    }
  }

  .lead {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      padding-right: 170px;
      margin-bottom: 56px;
    }
  }
}

// ------------------------------------------
.section-free-consultation {
  padding-top: 86px;
  padding-bottom: 77px;

  @include media-breakpoint-down(md) {
    padding-top: 60px;
    padding-bottom: 32px;
  }

  .container {
    max-width: 898px;
  }

  .lead {
    margin-bottom: 44px;

    @include media-breakpoint-down(md) {
      margin-bottom: 33px;
    }
  }

  .title-section {
    @include media-breakpoint-down(md) {
      margin-bottom: 19px;
    }
  }
}

// ------------------------------------------
.section-faq {
  padding-top: 50px;
  padding-bottom: 50px;

  @include media-breakpoint-up(md) {
    padding-top: 116px;
    padding-bottom: 105px;
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-right: 22px;
      padding-left: 22px;
    }
  }

  .title-section {
    margin-bottom: 26px;

    @include media-breakpoint-up(md) {
      margin-bottom: 68px;
    }
  }

  .title-secondary {
    margin-bottom: 18px;
    font-size: 22px;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      font-size: 26px;
    }
  }

  .lead {
    margin-bottom: 50px;
  }

  .inner {
    + .inner {
      margin-top: 60px;

      @include media-breakpoint-up(md) {
        margin-top: 130px;
      }
    }
  }
}

// ------------------------------------------
.section-access {
  padding-bottom: 90px;

  @include media-breakpoint-down(md) {
    padding-bottom: 47px;
  }

  &.blue {
    .container {
      &:after {
        @include media-breakpoint-up(md) {
          background-image: url("../img/icon/two-tree-1.svg");
        }
      }
    }
  }

  a {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .12em;
    line-height: 2;
    color: #d7715f;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 1.8;
    }
  }

  .container {
    position: relative;
    max-width: 1044px;

    @include media-breakpoint-up(md) {
      padding-bottom: 45px;
    }

    &:after {
      @include media-breakpoint-up(md) {
        position: absolute;
        width: 170px;
        height: 182px;
        bottom: 0;
        left: 0;
        background: url("../img/icon/two-tree.svg") 100% 100% / cover no-repeat;
        content: "";
      }
    }
  }

  .title-section {
    margin-bottom: 12px;

    @include media-breakpoint-down(md) {
      margin-bottom: 17px;
    }
  }

  .lead-container {
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      margin-top: 26px;
    }
  }

  .lead {
    @include media-breakpoint-down(lg) {
      letter-spacing: .05em;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .lead-lg {
    @include media-breakpoint-down(md) {
      margin-top: 10px;
      margin-bottom: 17px;
      font-size: 18px;
      letter-spacing: .12em;
    }
  }

  .lead-md {
    @include media-breakpoint-down(md) {
      margin-bottom: 9px;
      font-size: 13px;
      line-height: 2.153;
      letter-spacing: .12em;
    }
  }

  .lead-contact {
    @include media-breakpoint-down(md) {
      font-size: 14px;
      letter-spacing: .1em;
      line-height: 2;
    }
  }

  .icon-mail {
    margin-right: 5px;
  }

  .img-map {
    @include media-breakpoint-down(md) {
      margin-bottom: 26px;
    }
  }

  .map-container {
    height: 438px;
    margin-top: 65px;

    @include media-breakpoint-down(md) {
      height: 200px;
      margin-top: 15px;
    }

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}

@keyframes horizontalScrolling {
  0% {
    transform: translateX(50px);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50px);
  }
}

// ----------------------------------------------
.section-fv {
  position: relative;
  height: 775px;
  overflow: hidden;
  background: url("../img/common/sunshine-2.svg") #fae8d5 185px 0 no-repeat;
  background-size: cover;

  @media (max-width: 1280px) {
    height: 60vw;
  }

  @include media-breakpoint-down(md) {
    height: 149vw;
    background: url("../img/common/sunshine-2-sp.svg") #fae8d5 71% 0 no-repeat;
    background-size: cover;
  }

  .cloud-fv,
  .bg-fv,
  .title-img {
    position: absolute;
    opacity: 0;
    transition: 1s all ease;
  }

  &.active {
    .cloud-fv,
    .bg-fv,
    .title-img {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .bg-fv {
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
    object-position: 50% 0;
    transition-delay: .5s;

    @include media-breakpoint-up(xl) {
      object-fit: contain;
    }
  }

  .cloud-fv {
    top: -25%;
    left: -23.5%;
    transition-delay: .2s;
    animation: horizontalScrolling 5s infinite linear;

    @include media-breakpoint-down(xl) {
      top: -123px;
      left: -15%;
    }

    @include media-breakpoint-between(md,xl) {
      width: 122%;
    }

    @include media-breakpoint-down(lg) {
      top: -8%;
    }

    @include media-breakpoint-down(md) {
      top: -80px;
      left: -250px;
    }

    @include media-breakpoint-up(xxl) {
      top: -30%;
      left: 0;
      width: 100%;
    }
  }

  .title-img {
    position: absolute;
    margin-top: 0;
    top: 102px;
    right: 263px;

    @include media-breakpoint-down(xl) {
      right: 160px;
    }

    @include media-breakpoint-down(lg) {
      right: 18%;
    }

    @include media-breakpoint-down(md) {
      top: 29%;
      right: 0;
      left: 0;
      width: 122px;
      margin-right: auto;
      margin-left: auto;
    }

    img {
      @include media-breakpoint-down(lg) {
        width: 17vw;
      }

      @include media-breakpoint-down(md) {
        width: auto;
      }
    }
  }

  .text-vertical {
    position: absolute;
    bottom: 40px;
    left: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #624a2d;
    letter-spacing: .1em;
    writing-mode: vertical-rl;
    text-orientation: mixed;

    @include media-breakpoint-down(lg) {
      font-size: 1.5vw;
    }

    @include media-breakpoint-down(md) {
      top: 105px;
      bottom: auto;
      left: 14px;
      font-size: 3.5vw;
      margin-bottom: 0;
      letter-spacing: .15em;
    }
  }
}

// ----------------------------------------------
.section-our-activities {
  position: relative;
  padding-bottom: 48px;

  @include media-breakpoint-down(xl, (xl: 1366px)) {
    background-size: 18.6vw 21.1vw;
  }

  @include media-breakpoint-down(lg) {
    background-position-y: 42%;
  }

  @include media-breakpoint-up(lg) {
    background-image: url("../img/section/our-activities/footprint.svg");
    background-position: 100% 36.3%;
    background-repeat: no-repeat;
    background-size: 255px 282px;
  }

  @include media-breakpoint-down(xl, (xl: 1366px)) {
    background-size: 18.6vw 20.5vw;
  }

  @include media-breakpoint-down(md) {
    padding-top: 39px;
    padding-bottom: 33px;
  }

  &:after {
    @include media-breakpoint-down(lg) {
      position: absolute;
      right: 0;
      bottom: -141px;
      width: 140px;
      height: 174px;
      background-position-y: 100%;
      background-image: url("../img/section/our-activities/footprint-sp.svg");
      background-size: cover;
      content: "";
    }
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 12.4vw;
    bottom: -12.15vw;
    background-image: url("../img/section/our-activities/wave-white-lg.png");
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include media-breakpoint-down(md) {
      height: 14vw;
      bottom: -14vw;
      background-image: url("../img/section/our-activities/wave-white-lg-sp.png");
    }
  }

  .title-section {
    line-height: 1.4;
    margin-bottom: 23px;

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
    }
  }

  .lead {
    margin-bottom: 56px;

    @include media-breakpoint-up(md) {
      line-height: 2;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 33px;
      letter-spacing: .01em;
    }
  }

  .inner {
    max-width: 747px;

    @include media-breakpoint-down(md) {
      padding-right: 9px;
      padding-left: 9px;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    background-color: #cb6446;

    @include media-breakpoint-down(md) {
      width: 130px;
      height: 130px;
      margin-right: auto;
      margin-left: auto;
    }

    &.yellow {
      background-color: #ead430;
    }
  }

  .caption {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: .08em;
    line-height: 1.714;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-top: 12px;
      margin-bottom: 28px;
    }
  }

  .icon-1 {
    @include media-breakpoint-down(md) {
      width: 65px;
    }
  }

  .icon-2 {
    @include media-breakpoint-down(md) {
      width: 83px;
    }
  }

  .icon-3 {
    @include media-breakpoint-down(md) {
      width: 94px;
    }
  }

  .icon-4 {
    @include media-breakpoint-down(md) {
      width: 73px;
    }
  }

  .img-tree {
    @include media-breakpoint-down(md) {
      margin-top: -123px;
      margin-left: 16px;
    }
  }
}

// ---------------------------------------------
.section-problems {
  position: relative;
  padding-bottom: 60px;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md) {
    background-image: url("../img/section/problems/cloud-left.svg"), url("../img/section/problems/cloud-right.svg");
    background-position: 0 7%, 100% 72.2%;
    background-size: 29.35vw 22.9vw, 29.3vw 28.5vw;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 2px;
    background-image: url("../img/section/problems/cloud-left-sp.svg");
    background-position: 0 12.9%;
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 26.7vw;
    height: 21vw;
    background-image: url("../img/section/problems/mountain.svg");
    background-position: 0 100%;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";

    @include media-breakpoint-down(md) {
      width: 163px;
      height: 125px;
      background-image: url("../img/section/problems/mountain-sp.svg");
    }
  }

  &.blue {
    @include media-breakpoint-up(md) {
      background-image: url("../img/section/problems/cloud-left-1.svg"), url("../img/section/problems/cloud-right-1.svg");
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      background-image: url("../img/section/problems/cloud-left-blue-sp.svg");
    }

    &:after {
      background-image: url("../img/section/problems/mountain-1.svg");

      @include media-breakpoint-down(md) {
        background-image: url("../img/section/problems/mountain-1-sp.svg");
      }
    }

    .body {
      @include media-breakpoint-down(md) {
        padding-bottom: 14.4vw;
      }

      &:after {
        background-image: url("../img/section/problems/tree-top-1.svg");
      }
    }

    .container {
      &:after {
        background-image: url("../img/section/problems/tree-bottom-1.svg");
      }
    }
  }

  .body {
    position: relative;
    max-width: 986px;
    padding: 70px 89px 128px;
    margin-top: 74px;
    border-top-right-radius: 36px;
    border-top-left-radius: 36px;
    background-image: url("../img/section/problems/wave-white.png");
    background-position: 0 100%;
    background-size: 986px 140px;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
      padding-bottom: 175px;
      padding-left: 30px;
    }

    @include media-breakpoint-down(md) {
      padding: 40px 15px 12.4vw;
      margin-top: 94px;
      border-radius: 30px;
      background-image: url("../img/section/problems/wave-white-sp.png");
      background-size: 100% 150px;
    }

    &:after {
      position: absolute;
      top: -148px;
      right: 31px;
      width: 165px;
      height: 178px;
      background-image: url("../img/section/problems/tree-top.svg");
      background-size: cover;
      content: "";

      @include media-breakpoint-down(md) {
        top: -65px;
        right: 18px;
        width: 81px;
        height: 87px;
      }
    }

    .lead {
      margin-bottom: 73px;

      @include media-breakpoint-down(md) {
        margin-bottom: 60px;
        line-height: 2.143;
      }
    }
  }

  .container {
    position: relative;
    max-width: 1170px;

    &:after {
      @include media-breakpoint-up(md) {
        position: absolute;
        left: 10px;
        bottom: 7px;
        width: 184px;
        height: 224px;
        background-image: url("../img/section/problems/tree-bottom.svg");
        background-position: 0 100%;
        background-repeat: no-repeat;
        content: "";
      }
    }
  }

  .title-section {
    @include media-breakpoint-down(md) {
      margin-bottom: 28px;
      font-size: 24px;
      line-height: 1.667;
    }
  }
}

// -------------------------------------------------------
.section-form {
  padding-top: 200px;
  padding-bottom: 100px;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
  }

  .icon-star {
    position: relative;

    &:before {
      display: inline-block;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.01;
      color: #008b84;
      vertical-align: middle;
      content: "*";
    }
  }

  .form-control,
  .form-select {
    height: 46px;
    // margin-bottom: 30px;
    font-size: 16px;
    border-color: #f8f8f8;
    border-radius: 4px;
  }

  textarea {
    height: auto !important;
  }

  .form-check-label {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }

  .btn-container {
    max-width: 400px;
    margin-top: 30px;
  }

  .btn-common {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }

  a {
    color: #337ab7;
    text-decoration: none;

    &:hover {
      color: #23527c;
      text-decoration: underline;
    }
  }

  .text-red {
    color: #ff0000;
  }

  .input-date-1,
  .input-date-2 {
    width: 150px;
  }

  .input-time-1 {
    @include media-breakpoint-up(md) {
      margin-right: 30px;
    }
  }

  .input-date-1,
  .input-time-1 {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  .input-time-1,
  .input-time-2 {
    width: auto;
  }

  .input-group {
    input,
    select {
      height: 30px;
      flex: 0 0 auto;
      border: 1px solid #acacac;
      border-radius: 5px !important;

      @include media-breakpoint-up(md) {
        font-size: 12px;
      }

      @include media-breakpoint-down(md) {
        height: 38px;
      }
    }

    input {
      margin-right: 5px;
      margin-left: 5px !important;
    }

    .input-text {
      margin-top: 2px;
    }
  }

  .row {
    > div {
      margin-bottom: 30px;
    }
  }

  label {
    &.error {
      margin-top: 5px;
    }
  }

  .form-check {
    position: relative;

    label {
      &.error {
        position: absolute;
        bottom: -20px;
        left: 0;
      }
    }
  }
}

// ---------------------------------------
.section-thanks {
  padding-top: 250px;
  padding-bottom: 250px;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .title-section {
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      font-size: 17px;
    }
  }

  .btn-container {
    max-width: 400px;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      max-width: 300px;
      margin-top: 20px;
    }
  }

  .btn-common {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;

    @include media-breakpoint-down(md) {
      padding-top: 13px;
      padding-bottom: 13px;
      font-size: 15px;
    }
  }
}
