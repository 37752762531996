@charset "UTF-8";

// ----------------------------------------------
@mixin button-variant-hover-bright($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: lighten($background, 17%);
      border-color: lighten($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// ----------------------------------------------
@mixin button-variant-hover-inverse($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 25%);
  }

  &:hover {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $background;
      background-color: $color;
      // border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      // border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// Button
// ----------------------------------------------
// .btn-*** {
//   @include button-variant($btn-default-bg, $btn-default-border, $btn-default-color);
// }

// ----------------------------------------------
.btn-multiline {
  white-space: normal;
}

// ----------------------------------------------
.btn-black {
  @include button-variant-hover-inverse(#000, #000, #fff);
  padding: 16px 10px 15px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 32px;
}

// ----------------------------------------------
.btn-fixed-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: .16em;
  line-height: 1.1;
  border-radius: 20px 0 0 20px;
  z-index: 100;

  @include media-breakpoint-up(md) {
    width: 55px;
    writing-mode: vertical-rl;
  }

  @include media-breakpoint-down(md) {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 14px;
    border-radius: 15px 15px 0 0;
  }

  &:hover {
    &.orange {
      svg {
        path {
          fill: #d7715f;
        }
      }
    }

    &.blue {
      svg {
        path {
          fill: #024496;
        }
      }
    }

    &.green {
      svg {
        path {
          fill: #fff;

          &:first-child {
            fill: #2f6056;
          }
        }
      }
    }

    &.light-green {
      svg {
        path {
          fill: #fff;

          &:first-child {
            fill: #64961f;
          }
        }
      }
    }
  }

  &.orange,
  &.blue {
    @include media-breakpoint-up(md) {
      height: 187px;
      margin-bottom: 15px;
    }
  }

  &.green,
  &.light-green {
    @include media-breakpoint-up(md) {
      height: 220px;
    }

    span {
      @include media-breakpoint-up(md) {
        transform: rotate(-90deg);
        margin-bottom: 7px;
        margin-left: 4px;
      }
    }
  }

  &.orange {
    @include button-variant-hover-inverse(#d7715f, #d7715f, #fff);
  }

  &.blue {
    @include button-variant-hover-inverse(#024496, #024496, #fff);
  }

  &.green {
    @include button-variant-hover-inverse(#2f6056, #2f6056, #fff);
  }

  &.light-green {
    @include button-variant-hover-inverse(#64961f, #64961f, #fff);
  }

  svg {
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(md) {
      max-width: 20px;
      margin-right: 8px;
    }

    &.icon-message {
      @include media-breakpoint-down(md) {
        max-width: 17px;
      }
    }

    &.icon-line {
      @include media-breakpoint-down(md) {
        max-width: 22px;
      }
    }
  }
}

// ----------------------------------------------
.btn-container-fixed {
  position: fixed;
  right: 0;
  z-index: 100;

  @include media-breakpoint-up(md) {
    top: 234px;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    width: 100%;
    bottom: 0;
    transition: all .3s ease;

    &.is-scrolled {
      bottom: -52px;
    }
  }

  &.hide-btn-fixed {
    z-index: 9;

    @include media-breakpoint-up(md) {
      position: relative;
    }
  }
}

// ----------------------------------------------
.btn-common {
  padding: 35px 15px;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: .15em;
  border-radius: 57px;
  border-width: 2px;

  @include media-breakpoint-down(md) {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 17px;
    font-size: 16px;
  }

  &.orange {
    @include button-variant-hover-inverse(#d7715f, #d7715f, #fff);
  }

  &.green {
    @include button-variant-hover-inverse(#2f6056, #2f6056, #fff);
  }

  &.blue {
    @include button-variant-hover-inverse(#024496, #024496, #fff);
  }

  &.light-green {
    @include button-variant-hover-inverse(#64961f, #64961f, #fff);
  }
}
