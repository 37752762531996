@charset "UTF-8";

// ----------------------------------------------
mark,
.mark {
  display: inline-block;
  padding: .1em 0;
  background-color: #fcee21;
}

// Page header
// ----------------------------------------------
.page-header {
  position: relative;
  padding-top: 120px;
  padding-bottom: 115px;

  @include media-breakpoint-up(md) {
    min-height: 586px;
    padding-top: 226px;
  }

  .container {
    max-width: 1010px;

    @include media-breakpoint-down(md) {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .title {
    position: relative;
    z-index: 1;
    font-size: 24px;
    line-height: 1.79;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 1.8;
    }

    @include media-breakpoint-down(md) {
      letter-spacing: .07em;
    }
  }

  .img-bg {
    position: absolute;
  }

  .img-platform {
    bottom: -452px;
    left: 50%;
    width: 990px;
    transform: translateX(-55%);

    @include media-breakpoint-up(md) {
      bottom: -45vw;
      left: 0;
      width: 100%;
      transform: translateX(0);
    }
  }

  .img-sunshine {
    top: 0;
    right: -125px;
    width: 350px;

    @include media-breakpoint-up(md) {
      right: 0;
    }

    @include media-breakpoint-up(lg) {
      right: calc(50% - 683px);
      width: auto;
    }
  }

  .img-cloud-2 {
    top: -196px;
    right: -350px;
    animation: 5s linear infinite horizontalScrolling;

    @include media-breakpoint-up(md) {
      top: -200px;
      right: -200px;
    }

    @include media-breakpoint-up(lg) {
      top: -294px;
      right: calc(50% - 1025px);
      width: 798px;
      height: 356px;
    }
  }

  .img-cloud-3 {
    top: 57px;
    left: -280px;
    animation: 5s linear 2s infinite horizontalScrolling;

    @include media-breakpoint-up(md) {
      top: 120px;
      right: -220px;
    }

    @include media-breakpoint-up(lg) {
      top: 96px;
      left: calc(50% - 1000px);
      width: 683px;
      height: 211px;
    }
  }

  .img-visual {
    bottom: 43px;
    right: 0;
    width: 340px;

    @include media-breakpoint-up(md) {
      bottom: 30px;
      right: -60px;
      width: 450px;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    @include media-breakpoint-up(xl) {
      bottom: 80px;
      right: calc(50% - 536px);
    }
  }

  .img-tree {
    bottom: 0;
    left: 0;
    width: 50px;

    @include media-breakpoint-up(md) {
      width: 100px;
    }

    @include media-breakpoint-up(lg) {
      bottom: -27px;
      width: auto;
    }

    @include media-breakpoint-up(xl) {
      left: calc(50% - 700px);
    }
  }
}

// ----------------------------------------------
strong {
  font-weight: bold;
}

// ----------------------------------------------
ul,
ol {
  padding-left: 1.5em;
}

// ----------------------------------------------
.list-inline {
  margin-left: 0;

  > li {
    &:first-child {
      padding-left: 0;
    }
  }

  &.bordered {
    > li {
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 10px;
      border-right: 1px solid #999;

      &:first-child {
        border-left: 1px solid #999;
      }
    }
  }
}

// ----------------------------------------------
.dl-list-indent {
  margin-bottom: 0;

  &,
  dd {
    overflow: hidden;
    zoom: 1;
  }

  dt,
  dd {
    display: table-cell;
    vertical-align: top;
  }

  dt {
    padding-right: 5px;
    font-weight: normal;

    img {
      display: block;
    }
  }

  dd {
    width: 10000px;
  }

  &.cleared-md {
    @include media-breakpoint-down(md) {
      dt {
        display: block;
      }

      dd {
        padding-top: 0;
      }
    }
  }
}

// ----------------------------------------------
.text-blue {
  color: #024496 !important;
}

// ----------------------------------------------
.d-flex-number {
  @include media-breakpoint-down(md) {
    position: relative;
    min-height: 90px;
  }

  .flex-title {
    width: 100%;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: .12em;
    line-height: 1.3;

    @include media-breakpoint-down(lg) {
      font-size: 18px;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      min-height: 61px;
      padding-top: 25px;
      padding-left: 115px;
      font-size: 16px;
      line-height: 1.125;
    }
  }

  .flex-shrink-0 {
    @include media-breakpoint-down(md) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    img {
      @include media-breakpoint-between(md, lg) {
        max-width: 100px;
      }
    }
  }

  .flex-grow-1 {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 30px;

    @include media-breakpoint-down(lg) {
      margin-left: 20px;
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  .caption {
    margin-top: 12px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .12em;
    line-height: 1.714;

    @include media-breakpoint-down(lg) {
      font-size: 12px;
    }

    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 1.846;
      margin-top: 41px;
      letter-spacing: .1em;
    }
  }
}

// --------------------------------------------
.list-tick {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .12em;
  line-height: 1.857;

  @include media-breakpoint-down(md) {
    font-size: 13px;
    line-height: 1.846;
  }

  li {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 15px;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding-left: 28px;
      margin-bottom: 9px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      width: 23px;
      height: 13px;
      top: 8px;
      left: 0;
      background: url("../img/icon/tick.svg");
      background-size: cover;
      content: "";

      @include media-breakpoint-down(md) {
        top: 6px;
      }
    }
  }

  &.yellow {
    li {
      &:before {
        background-image: url("../img/icon/tick-yellow.svg");
      }
    }
  }
}

// --------------------------------------------
.lead {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .12em;
  line-height: 2.286;

  &.lead-md {
    font-size: 16px;
    line-height: 2;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 1.8;
    }
  }

  &.lead-lg {
    font-size: 18px;
    line-height: 1.778;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 1.6;
    }
  }
}

// ----------------------------------------------
@mixin text-shadow-outline-blurred($color) {
  text-shadow:
    // outline
    2px 2px 0 $color,
    2px -2px 0 $color,
    -2px 2px 0 $color,
    -2px -2px 0 $color,
    2px 0 0 $color,
    0 2px 0 $color,
    -2px 0 0 $color,
    0 -2px 0 $color,

      // blur
    2px 3px 2px $color,
    2px -3px 2px $color,
    -2px 3px 2px $color,
    -2px -3px 2px $color,
    3px 0 2px $color,
    0 3px 2px $color,
    -3px 0 2px $color,
    0 -3px 2px $color;

  @include media-breakpoint-down(md) {
    text-shadow:
      // outline
      1px 1px 0 $color,
      1px -1px 0 $color,
      -1px 1px 0 $color,
      -1px -1px 0 $color,
      1px 0 0 $color,
      0 1px 0 $color,
      -1px 0 0 $color,
      0 -1px 0 $color,

        // blur
      1px 2px 1px $color,
      1px -2px 1px $color,
      -1px 2px 1px $color,
      -1px -2px 1px $color,
      2px 0 1px $color,
      0 2px 1px $color,
      -2px 0 1px $color,
      0 -2px 1px $color;
  }
}
